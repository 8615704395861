<template>
  <Transition name="fade">
    <div class="adminPanel" v-if="modelValue">
      <div class="adminPanel__menu">
        <button class="adminPanel__action" :class="{'active':mode == name}" v-for="(text, name) in modes" :key="name" @click="mode = name">{{text}}</button>
        <button class="adminPanel__action" @click.stop="$emit('update:modelValue', false)">Выйти</button>
      </div>
      <div class="adminPanel__view">
        <component v-if="mode" :is="mode" />
      </div>
    </div>
  </Transition>
</template>

<script>
import CreateUser from './CreateUser.vue';
import EditUser from './EditUser.vue';
import DeleteUser from './DeleteUser.vue';
import AzonesControl from './AzonesControl.vue';
import DeleteDomains from './DeleteDomains.vue';
export default {
  props: {
    modelValue: {
      type: Boolean, default: false
    }
  },
  data: () => ({
      mode: "",
      modes: {
        'CreateUser': 'Создать пользователя',
        'EditUser': 'Изменить пользователя',
        'DeleteUser': 'Удалить пользователя',
        'DeleteDomains': 'Удалить домены',
        'AzonesControl': 'Зоны доступа',
      }
  }),
  components: { CreateUser, EditUser, DeleteUser, AzonesControl, DeleteDomains }
}
</script>

<style lang="sass">
.adminPanel
  display: flex
  position: fixed
  z-index: 100
  top: 0
  left: 0
  width: 100%
  height: 100%
  &__menu
    width: min-content
    height: 100%
    overflow-y: auto
    box-sizing: border-box
    padding-top: 30px
    background: darken(colors.$bootactive, 10)
    box-shadow: 2px 2px 2px rgba(0,0,0,.2)
  &__action
    width: 100%
    border: none
    border-bottom: 1px solid colors.$bg
    background: colors.$bootblue
    outline: none
    color: white
    font-weight: 500
    font-size: 20px
    white-space: nowrap
    padding: 15px 30px
    text-align: left
    cursor: pointer
    &:focus-visible, &.active
      background: colors.$blue
  &__view
    flex-grow: 1
    padding: 20px
    background: colors.$bg
      


</style>