<template>
  <button class="deletesite" @click="deleteProject" title="Удалить проект" v-if="folder">
    <svg viewBox="0 0 25 25">
      <g transform='translate(3.966 3.568)'>
        <path d='M0-.001l17.435 18.213M0 18.212L17.435 0' stroke-width='8' stroke-linecap='round'></path>
      </g>      
    </svg>
  </button>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState(['id','folder','url'])
    },
    methods: {
      deleteProject(){
        if (!this.folder) return alert('Выберите папку')
        if (confirm(`Удалить папку ${this.folder}? (сайты останутся)`)) {
          fetch(this.url + `folder/${this.folder}`, {
            method: 'DELETE',
            credentials: 'include',
          }).then(async res=>{
            if (res.ok) return res.status
            throw(await res.text())
          }).then(async () => {
            await this.$store.dispatch('getProjects')
            this.$store.commit('setId', undefined)
            this.$store.commit('setFolder', undefined)
            this.$router.push(`/app${this.folder?`/${this.folder}`:''}`)
          }).catch(err=>alert(err))
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
.deletesite
  border: 1px solid colors.$blue
  padding: 0
  outline: none
  width: 30px
  height: 30px
  display: flex
  cursor: pointer
  border-radius: 5px
  font-size: 24px
  align-items: center
  justify-content: center
  text-align: center
  background: lighten(colors.$blue, 30%)
  color: darken(colors.$blue, 30%)
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)
  svg
    filter: drop-shadow(-1px -1px 1px rgba(255,255,255,.1)) drop-shadow(1px 1px 1px rgba(0,0,0,.5))
    stroke: darken(colors.$blue, 30%)
    width: 12px
    height: 12px

</style>