<template>
  <Transition name="fade">
    <div v-if="locked || pending" class="loadingpop">
      <div class="loadingpop__content" style="margin: auto">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin-bottom: 20px; display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="rotate(0 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-1.3681592039800994s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(30 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-1.243781094527363s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(60 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-1.1194029850746268s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(90 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-0.9950248756218905s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(120 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-0.8706467661691542s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(150 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-0.7462686567164178s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(180 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-0.6218905472636815s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(210 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-0.49751243781094523s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(240 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-0.3731343283582089s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(270 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-0.24875621890547261s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(300 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="-0.12437810945273631s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(330 50 50)">
            <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#007bff">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.4925373134328357s" begin="0s" repeatCount="indefinite"></animate>
            </rect>
          </g>
        </svg>
        <div class="loadingpop__status" v-if="locked && (locked !== true)">{{ locked }}</div>
      </div>
    </div>
  </Transition>
  <Transition name="fade">
    <LoginWindow v-if="!pending && !authorized" />
  </Transition>

  <div class="main" v-if="!pending && authorized">
    <div class="main__panel">
      <AdminBtn v-if="authorized == 2" @closed="updateGFDifNeeded" />
      <FolderSelect />
      <AddProject v-if="authorized == 2" />
      <EditProject v-if="folder && (authorized == 2)" @gfd="updateGFDifNeeded" />
      <DeleteProject v-if="folder && (authorized == 2)" />
      <SiteSelect v-if="folder" />
      <DeleteSite v-if="authorized == 2" />
      <LogoutBtn />
    </div>
    <div class="main__content" v-if="folder">
      <div class="main__pagesblock">
        <form class="main__subform" v-if="project && (project.yandex || project.yandexSPB || project.google || project['КС'])">
          <div class="main__subform-flex">
            <legend>Данные:</legend>
            <label v-if="project.yandex"><input type="radio" name="mode" value="yandex" v-model="mode">Яндекс МСК</label>
            <label v-if="project.yandexSPB"><input type="radio" name="mode" value="yandexSPB" v-model="mode">Яндекс СПБ</label>
            <label v-if="project.google"><input type="radio" name="mode" value="google" v-model="mode">Google</label>
            <label v-if="project['КС']"><input type="radio" name="mode" value="КС" v-model="mode">КС</label>
          </div>
        </form>
      </div>
      <div class="main__charts" v-if="enabled">
        <Apexchart width="550" type="line" :options="chartOptions()" :series="series()" />
        <div class="main__charts-cols">
          <Apexchart ref="brochart" width="550" height="400" type="column" :options="columnChartOptions()" :series="columnChartSeries" />
          <div class="main__charts-date">
            <select v-model="date">
              <option v-for="d in dates" :key="d" :value="d">{{ d }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="main__charts main__charts_center" v-if="!locked && folderViewEnabled">
        <Apexchart ref="folderchart" v-if="!GFD.alerttext" width="550" type="line" :options="chartFolderOptions()" :series="GFD.chartSeries" />
        <div v-else style="font-width: 700; font-size: 30px">{{ GFD.alerttext }}</div>
      </div>
      <div class="main__view">
        <Table @dataUpdated="getProject" :mode="mode" :vaf="vaf" @changeVaf="viewvaf = true" v-if="enabled && !(vaf && viewvaf)" :info="project[mode]" :dates="dates" :modeColor="modeColor" @export="exportData" @exportVig="exportDataVig" />
        <FreqTable @changeVaf="viewvaf = false" v-if="enabled && vaf && viewvaf" :info="project[mode]" :rawdates="dates" :modeColor="modeColor" />
        <div class="main__subform-flex" v-if="!locked && folderViewEnabled && !GFD.alerttext && GFD.vaftables">
          <legend>Таблица:</legend>
          <label><input type="radio" name="gfdmode" value="" v-model="GFD.mode">Число запросов</label>
          <label v-for="(el, type) in GFD.vaftables" :key="type"><input type="radio" name="gfdmode" :value="type" v-model="GFD.mode">ОТЧ {{ type == 'yandex' ? 'Яндекс МСК' : (type == 'yandexSPB' ? 'Яндекс СПБ' : type) }}</label>
        </div>
        <FolderTable v-if="!locked && folderViewEnabled && !GFD.alerttext" :dates="GFD.mode ? GFD.vafdates : GFD.dates" :data="GFD.mode ? GFD.vaftables[GFD.mode] : GFD.tableData" />
        <div class="filler"></div>
        <div class="main__subform" v-if="folder && (authorized == 2)">
          <input type="file" ref="finput" multiple @change="postData">
          <button :disabled="locked" @click.prevent.stop="$refs.finput.click()" class="btn btn_blue">Загрузить данные</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SiteSelect from '../components/SiteSelect.vue'
import DeleteSite from '../components/DeleteSite.vue'
import Table from '../components/Table.vue'
import Apexchart from 'vue3-apexcharts'
import LoginWindow from '../components/LoginWindow.vue'
import LogoutBtn from '../components/LogoutBtn.vue'
import FolderSelect from '../components/FolderSelect.vue'
import AddProject from '../components/AddProject.vue'
import EditProject from '../components/EditProject.vue'
import DeleteProject from '../components/DeleteProject.vue'
import AdminBtn from '../components/AdminBtn.vue'
import FolderTable from '../components/FolderTable.vue'
import FreqTable from '../components/FreqTable.vue'
import Pako from 'pako'
import msgpack from 'msgpack-lite';


export default {
  name: 'Main',
  data: () => ({
    GFD: { mode: '' },
    vaf: false,
    viewvaf: false,
    project: undefined,
    mode: undefined,
    date: undefined
  }),
  methods: {
    updateGFDifNeeded() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (!this.project && this.folder)
            this.getFolderData()
        })
      })
    },
    getFolderChartSeries(chartData) {
      let res = []
      for (let type in chartData)
        res.push({
          name: 'Число запросов ' + type,
          data: chartData[type]
        })
      return res
    },
    getModeColor(mode) {
      if (!mode) return '#000000'
      if (mode.includes('yandex')) return '#FFC107'
      if (mode == 'КС') return '#AAFFAA'
      return '#4680FF'
    },
    columnChartSeriesFn() {
      return this.columnChartSeries
    },
    generateColumnChartDataSet(dates) {
      let keys = ['1-10', '11-20', '21-30', '31-50', '51-100']
      let cats = {}
      for (let key of keys)
        cats[key] = new Array(dates.length).fill(0)
      for (let i = 0; i < dates.length; i++) {
        let date = dates[i]
        let data = this.project[this.mode][date]
        for (let line of data) {
          let p = line.pos
          if (p >= 1 && p <= 10)
            cats[keys[0]][i]++
          if (p >= 11 && p <= 20)
            cats[keys[1]][i]++
          if (p >= 21 && p <= 30)
            cats[keys[2]][i]++
          if (p >= 31 && p <= 50)
            cats[keys[3]][i]++
          if (p >= 51 && p <= 100)
            cats[keys[4]][i]++
        }
      }
      return cats
    },
    async exportData(data) {
      this.$store.commit('setLocked', { component: 'main', value: true })
      try {
        let dates = this.dates.filter((d, i) => (i >= data.interval[0]) && (i <= data.interval[1]))
        let reqnumber = this.series(dates)[0].data
        data.reqnumber = reqnumber
        let coldata = this.generateColumnChartDataSet(dates)
        data.coldata = coldata
        data.dates = dates
        data.id = this.id
      } catch (err) { alert(err); return this.$store.commit('setLocked', { component: 'main', value: false }) }
      await fetch(this.url + 'export/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(res => {
        if (res.ok)
          return res.blob()
        throw ('Ошибка экспорта данных (' + res.status + ')')
      })
        .then((blob) => {
          let dates = data.dates
          var url = window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.style = 'position: absolute'
          a.href = url
          a.download = `Отчёт ${this.id} ` + (dates.length > 1 ? `с ${dates[0]} по ${dates[dates.length - 1]}` : `за ${dates[0]}`) + '.xlsx'
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
        .catch(err => alert(err))
      this.$store.commit('setLocked', { component: 'main', value: false })
    },
    async exportDataVig(interval) {
      this.$store.commit('setLocked', { component: 'main', value: true })
      let dates = this.dates
      try {
        if (interval[0] !== undefined)
          dates = dates.filter((d, i) => (i >= interval[0]) && (i <= interval[1]))
      } catch (err) { alert(err); return this.$store.commit('setLocked', { component: 'main', value: false }) }
      await fetch(this.url + 'exportVig/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: this.id, dates, type: this.mode, folder: this.folder })
      }).then(res => {
        if (res.ok)
          return res.blob()
        throw ('Ошибка экспорта данных (' + res.status + ')')
      })
        .then((blob) => {
          var url = window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.style = 'position: absolute'
          a.href = url
          a.download = `Выгрузки ${this.id} - ${this.mode} ` + (dates.length > 1 ? `с ${dates[0]} по ${dates[dates.length - 1]}` : `за ${dates[0]}`) + '.xlsx'
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
        .catch(err => alert(err))
      this.$store.commit('setLocked', { component: 'main', value: false })
    },
    async getProject(id) {
      if (!id)
        id = this.id
      if (!id) {
        this.project = undefined
        this.mode = undefined
        this.date = undefined
        return
      }
      this.$store.commit('setLocked', { component: 'main', value: true })
      await fetch(this.url + `data/${this.folder}/${id}`, { credentials: 'include' })
        .then(res => {
          if (!res.ok)
            throw `Ошибка: проект не получен ${res.status}`
          return res.json()
        })
        .then(project => {
          if (!project || (!project.yandex && !project.yandexSPB && !project.google && !project['КС']))
            throw 'Ошибка: данные о проекте не получены'
          let found = false
          for (let mode of ['yandex', 'yandexSPB', 'google', 'КС'])
            if (project[mode] && Object.keys(project[mode]).length) {
              this.mode = mode
              found = true
              break
            }
          if (!found)
            throw 'Ошибка: Проект не содержит данных'
          this.project = project
        })
        .catch(err => alert(err))
      this.$store.commit('setLocked', { component: 'main', value: false })
    },
    async getFolderData() {
      if (!this.folder)
        return console.error('Не указана папка (GFD)')
      const f = this.folder
      console.log('here')
      this.$store.commit('setLocked', { component: 'mainGFD', value: true })
      await fetch(this.url + `folderinfo/${f}`, { credentials: 'include' })
        .then(async res => {
          if (!res.ok)
            throw (await res.text())
          return res.json()
        }).then(res => {
          let obj = {
            mode: '',
            name: f,
            chartData: res.chartData,
            chartSeries: this.getFolderChartSeries(res.chartData),
            tableData: res.tableData,
            dates: res.dates,
            vaftables: res.vaftables,
            vafdates: res.vafdates
          }
          console.log(res)
          if (obj.dates.length == 0)
            obj.alerttext = 'Нет данных по папке (все проекты пусты)'
          obj.chartColors = Object.keys(res.chartData).map(el => this.getModeColor(el))
          for (let p in obj.tableData)
            obj.tableData[p] = obj.tableData[p].map(el => el === null ? 0 : el)
          this.GFD = obj
          setTimeout(() => {
            this.$refs.folderchart.updateOptions(this.chartFolderOptions(), true, false, true)
            this.$refs.folderchart.updateSeries(this.GFD.chartSeries)
          }, 50)
        })
        .catch(err => {
          console.error(err)
          alert(err)
        })
      this.$store.commit('setLocked', { component: 'mainGFD', value: false })
    },
    series(dates = this.dates) {
      let s = []
      for (let date of dates)
        s.push(this.project[this.mode][date].length)
      let res = [
        {
          name: 'Число запросов ' + this.mode,
          data: s
        }
      ]
      return res
    },
    maxseries(s) {
      if (!s)
        return 1
      let a = s.map(el => Math.max(...el.data))
      return Math.ceil(Math.max(...a) * 1.1) || 1
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        colors: [this.modeColor],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.dates,
        },
        yaxis: {
          min: 0,
          max: this.maxseries(this.series())
        },
        title: {
          text: 'Число запросов',
          align: 'center',
          style: {
            color: '#444'
          }
        }

      }
    },
    chartFolderOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        colors: this.GFD.chartColors,
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.GFD.dates,
        },
        yaxis: {
          min: 0,
          max: this.maxseries(this.GFD.chartSeries)
        },
        title: {
          text: `Число запросов проектов из ${this.GFD.name}`,
          align: 'center',
          style: {
            color: '#444'
          }
        }

      }
    },
    columnChartOptions() {
      return {
        chart: {
          height: 350,
          type: 'bar',
          zoom: {
            enabled: false
          }
        },
        colors: [this.modeColor],
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: 'top', // top, center, bottom,
              colors: ['#000'],
            },
          }
        },
        stroke: {
          curve: 'straight',
          width: 0
        },
        dataLabels: {
          enabled: true,
          offsetY: -30,
          style: {
            fontSize: '14px',
            colors: ['#000'],
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.5,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45
            }
          }
        },

        xaxis: {
          categories: ["1-10", "11-20", "21-30", "31-50", "51-100"],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: true
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          min: 0
        },
        title: {
          text: 'Распределение запросов по позициям',
          align: 'center',
          style: {
            color: '#444'
          }
        }
      };
    },
    getDate(name) {
      if (typeof (name) !== 'string')
        return
      let res = name.match(/^\d\d\.\d\d\.\d\d/)
      if (res)
        return res[0]
    }
    ,
    getType(name) {
      if (typeof (name) !== 'string')
        return
      if (name.match(/\.msk\.csv$/))
        return 'yandex'
      if (name.match(/\.spb\.csv$/))
        return 'yandexSPB'
      if (name.match(/\.gru\.csv$/))
        return 'google'
      if (name.match(/\.ks\.csv$/))
        return 'КС'
    }
    ,
    getName(name) {
      name = name.replace(/^..\...\.../, '')
      name = name.replace(/\.organic.*$/, '').replace(/.ks.csv$/, '')
      return name
    },
    processFile(file, content) {
      let type = this.getType(file)
      if (!type) throw ('Не могу определить тип файла ' + file)
      let date = this.getDate(file)
      if (!date) throw ('Не могу определить дату файла ' + file)
      let name = this.getName(file)
      if (!name) throw ('Не могу определить имя файла ' + file)
      content = content.split('\n')
      let reqs = []
      let rc = 0, pc = 1, posc = 2, freqcol = 3, vafc = false
      if (type == 'КС') {
        pc = 17
        posc = 16
      }
      for (let i in content) {
        if (i == 0) {
          let h = content[i].trim().split(';')[4].trim()
          if (h.toLowerCase() == 'очень точная частотность')
            vafc = 4
          continue
        }
        let line = content[i].trim()
        let split, req, page, pos, freq, vaf
        if (!line) continue
        try {
          split = line.split(';')
          req = split[rc].replace(/"/g, '').trim()
          page = split[pc].replace(/"/g, '').trim()
          pos = split[posc].replace(/"/g, '').trim()
          freq = split[freqcol].replace(/"/g, '').trim()
          if (vafc)
            vaf = split[vafc].replace(/"/g, '').trim()
        } catch (err) {
          console.error(`Ошибка при обработке линии ${i} в файле ${name}:`)
          console.error(line)
          console.error(split)
          throw (err)
        }
        if (pos == '-1') continue
        let obj = {
          req, page, pos, f: freq
        }
        if (vafc)
          obj.vaf = vaf
        reqs.push(obj)
      }

      return { id: name, type: type, date: date, value: reqs }
    },
    async postData() {
      let globalStart = new Date()
      let files = this.$refs.finput.files
      this.$store.commit('setLocked', { component: 'main', value: 'Начинаю обработку файлов...' })
      let fun = async () => {
        let sum = 0
        for (let i = 0; i < files.length; i++)
          sum += files[i].size
        if (sum > 2e9) {
          alert('Превышен максимальный вес файлов')
          return this.$store.commit('setLocked', { component: 'main', value: false })
        }

        let start = performance.now()
        let res = [], errors = []
        let i = 0
        for (let file of files) {
          this.$store.commit('setLocked', { component: 'main', value: `Обрабатываю файл ${++i}...` })
          try {
            res.push(this.processFile(file.name, await file.text()))
          } catch (err) {
            errors.push(err)
          }
        }

        console.log((performance.now() - start) / files.length)
        console.log(errors)
        if (errors.length)
          return alert(errors.join('\n'))
        this.$store.commit('setLocked', { component: 'main', value: 'Подготавливаю данные к отправке...' })
        await new Promise(resolve => setTimeout(resolve, 100))
        console.log(res)
        res = Pako.gzip(msgpack.encode(res))

        this.$store.commit('setLocked', { component: 'main', value: 'Передаю данные на сервер...' })

        await fetch(this.url + `data/${this.folder}`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          body: res,
        }).then(async res => {
          console.log('response:')
          console.log(res)
          if (res.ok) return await res.text()
          let error = await res.text()
          throw (error)
        }).then(job_id => {
          console.log(job_id)
          this.$store.dispatch('waitJob', {
            job: job_id,
            start: globalStart,
            cb: async () => {
              await this.$store.dispatch('getProjects')
              await this.getProject()
              if (this.folderViewEnabled)
                this.getFolderData()
            }
          })
        })
          .catch(err => alert(err))
      }
      await fun().catch(err => alert(err))
      this.$store.commit('setLocked', { component: 'main', value: false })
    }
  },
  components: { SiteSelect, DeleteSite, Apexchart, Table, LoginWindow, LogoutBtn, FolderSelect, AddProject, EditProject, DeleteProject, AdminBtn, FolderTable, FreqTable },
  watch: {
    projmode(v) {
      if (v) {
        for (let date in v)
          if (v[date].length && (v[date][0].vaf !== undefined))
            return this.vaf = true
      }
      this.vaf = false
    },
    project(val, old) {
      if (!val && val != old && this.folder)
        this.getFolderData()
    },
    folder(val, old) {
      if (!this.project && val && val != old)
        this.getFolderData()
    },
    '$route.params.folder'() {
      console.log(this.$route.params.folder)
      this.$store.commit('setFolder', this.$route.params.folder)
    },
    '$route.params.id'() {
      this.$store.commit('setId', this.$route.params.id)
    },
    async id(v, old) {
      if (v != old)
        await this.getProject()
    },
    dates(v) {
      if (v && v.length && (!this.date || !v.includes(this.date)))
        this.date = v[0]
    },
    enabled() {
      setTimeout(() => {
        this.$refs.brochart.updateOptions(this.columnChartOptions(), true, false, true)
        this.$refs.brochart.updateSeries(this.columnChartSeries)
      }, 50)
    },
  },
  created() {
    let base = window.location.origin
    if (process.env.NODE_ENV == 'development') {
      console.log("DEV_MODE")
      base = base.replace('8080', '3015')
    }
    console.log(base)
    this.$store.commit('setUrl', base + '/')
    document.querySelector('title').innerHTML = 'Mstat'
  },
  async mounted() {
    await this.$store.dispatch('checkLoging')
    if (this.$route.params.folder)
      this.$store.commit('setFolder', this.$route.params.folder)
    if (this.$route.params.id)
      this.$store.commit('setId', this.$route.params.id)
  },
  computed: {
    ...mapState(['id', 'url', 'folder', 'authorized', 'pending']),
    ...mapGetters(['locked']),
    projmode() {
      if (!this.project || !this.project[this.mode])
        return false
      return this.project[this.mode]
    },
    folderViewEnabled() {
      if (this.folder && !this.project)
        return true
      return false
    },
    enabled() {
      return (this.project && this.project[this.mode] && this.authorized && !this.pending) ? true : false
    },
    modeColor() {
      if (!this.mode) return '#000000'
      if (this.mode.includes('yandex')) return '#FFC107'
      if (this.mode == 'КС') return '#AAFFAA'
      return '#4680FF'
    },
    columnChartSeries() {
      let cats = [0, 0, 0, 0, 0]
      try {
        let data = this.project[this.mode][this.date]
        for (let line of data) {
          let p = line.pos
          if (p >= 1 && p <= 10)
            cats[0]++
          if (p >= 11 && p <= 20)
            cats[1]++
          if (p >= 21 && p <= 30)
            cats[2]++
          if (p >= 31 && p <= 50)
            cats[3]++
          if (p >= 51 && p <= 100)
            cats[4]++
        }
      } catch (err) { console.error(err) }
      return [{
        name: 'Запросов',
        data: cats
      }]
    },
    dates() {
      if (!this.enabled) return []
      let dates = Object.keys(this.project[this.mode])

      dates.sort((dd1, dd2) => {
        let d1 = dd1.split('.')
        let d2 = dd2.split('.')
        d1 = parseInt(d1[0]) + parseInt(d1[1]) * 32 + parseInt(d1[2]) * 1000
        d2 = parseInt(d2[0]) + parseInt(d2[1]) * 32 + parseInt(d2[2]) * 1000
        return d1 - d2
      })
      return dates
    },
  }
}
</script>
<style lang="sass">
.important
  color: red !important
  font-size: 24px !important
  font-weight: 700 !important
.loadingpop
  position: fixed
  z-index: 100
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  background: rgba(255,255,255,.5)
  &__status
    font-size: 30px
    font-weight: 500
  &__content
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
  & + .main
    pointer-events: none
.fade-enter-active,.fade-leave-active 
  transition: opacity 0.5s ease
.fade-enter-from,.fade-leave-to 
  opacity: 0
.filler
  flex-grow: 1
.main
  background: white
  height: 100%
  width: 100%
  position: absolute
  background: colors.$bg
  padding: 20px
  display: flex
  flex-direction: column
  line-height: 1.2
  z-index: 1
  &::before
    content: ''
    position: absolute
    left: 0
    top: 0
    right: 0
    bottom: 0
    z-index: -1
    filter: brightness(115%) blur(2px)
    background: url('~@/assets/bg.webp')
  &__panel
    display: flex
    align-items: center
    justify-content: center
    gap: 5px
    margin-bottom: 20px
    select
      min-width: 250px
      margin-right: 10px
  &__content
    border-radius: 2px
    padding: 10px
    margin: -10px
    margin-top: 0
    outline: 1px dashed rgba(0,0,0,.1)
    flex-grow: 1
  &__charts
    display: flex
    justify-content: space-between
    max-width: 1150px
    margin: 0 auto
    &_center
      justify-content: center
    &-cols
      position: relative
    &-date
      position: absolute
      top: 5px
      right: 35px
  &__pagesblock
    width: 100%
    display: flex
    justify-content: center
    padding: 20px 0
    .main__subform
      min-width: 290px
    h2
      font-size: 20px
      font-weight: 700
      margin-bottom: 10px
  &__pages
    flex-grow: 1
    display: flex
    flex-direction: column
    width: 300px
    max-width: 100%
    textarea
      padding: 12px 12px
      outline: none
      width: 100%
      resize: none
      min-height: 300px
      border: none
      box-shadow: 2px 2px 3px rgba(0,0,0,.1)
      flex-grow: 1
      background: white
  &__subform
    input[type=file]
      visibility: hidden

    & &-flex, &-flex
      gap: 20px
      display: flex
      margin-bottom: 10px
      input[type=radio]
        margin-right: 4px
    & &-btnflex
      gap: 40px
      display: flex
      .btn
        width: fit-content

    input[type=checkbox]
      margin-right: 5px
    label
      display: block
  &__view
    padding-top: 20px
    display: flex
    flex-direction: column
    position: relative
    width: 1110px
    min-width: fit-content
    margin: 0 auto
    flex-grow: 1
    .btn
      width: 100%
      display: block
</style>
