<template>
  <div class="tablecontrolflex">
    <button class="tablesave btn btn_blue" @click="save">Сохранить таблицу</button>
  </div>
  <table :style="{'--modecolor': '#4680FF'}" :data-cols-width="getColWidth()" v-if="data && dates" class="table" ref="table" :class="{'sorted':sort.by && !sort.reverse, 'reversed':sort.by && sort.reverse}">
    <tr class="headline">
      <td data-a-h="center" data-a-v="middle" :class="{'active':sort.by == 'name'}" @click="changeSort('name')" ></td>
      <td data-a-h="center" data-a-v="middle" v-for="(date, i) in dates" :key="date" :class="{'active':sort.by == 'date' && sort.dateindex == i}" @click="changeSort('date', i)">{{date}}</td>
    </tr>
    <tr v-for="project of sortedprojects" :key="project">
      <td data-a-v="middle" class="link"><div>{{project}}</div></td>
      <td data-a-h="center" data-a-v="middle" v-for="(count,i) in data[project]" :key="page + ' - ' + i">{{count}}</td>
    </tr>
  </table>
</template>

<script>
import TableToExcel from "@linways/table-to-excel"
  export default {
    data: ()=>({
      sort: {
        by: 'name',
        dateindex: undefined,
        reverse: false
      }
    }),
    props: {
      data: {},
      dates: []
    }, 
    computed:{
      sortedprojects(){
        let projects = Object.keys(this.data)
        if (!this.sort.by)
          return projects
        if (this.sort.by == 'name')
          projects.sort((a,b)=>(a.localeCompare(b)) * (this.sort.reverse?(-1):1))
        if (this.sort.by == 'date')
          projects.sort((a,b)=>
            (this.data[a][this.sort.dateindex] - this.data[b][this.sort.dateindex]) 
            * (this.sort.reverse?(-1):1))
        return projects
      }
    },
    watch: {
      data(){
        if (this.sort.by != 'name')
          this.sort = {
            by: 'name',
            dateindex: undefined,
            reverse: false
          }
      }
    },
    methods: {
      getColWidth(){
        let str = '40' + this.dates.map(()=>',10').join('')
        return str
      },
      save(){
        TableToExcel.convert(this.$refs.table, {
          name: "table.xlsx",
          sheet: {
            name: "Данные"
          }
        })
      },
      changeSort(by, index) {
        if (index !== undefined)
          this.sort.dateindex = index
        if (this.sort.by == by)
          this.sort.reverse = !this.sort.reverse
        else {
          this.sort.reverse = false
          this.sort.by = by
        }
      }
    }
  }
</script>

<style lang="sass">

</style>