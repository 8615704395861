<template>
  <div class="adminform">
    <div style="display: flex; flex-wrap: wrap; gap: 40px;">
      <div>
        <label class="adminform__label">
          <div class="adminform__label-text">Создать зону доступа</div>
          <input type="text" autocomplete="off" v-model="azcreate">
        </label>
        <div class="adminform__msg" v-if="msg">{{msg}}</div>
        <div v-if="azcreate.trim()">
          <button class="adminform__btn btn btn_blue" @click.stop="create">Создать</button>
        </div>
      </div>
      <div>
        <div class="adminform__label-text">Удалить зону доступа</div>
        <select v-model="azone">
          <option value=""></option>
          <option v-for="a in azones" :key="a" :value="a">{{a}}</option>
        </select>
        <div class="adminform__msg" v-if="msg2">{{msg2}}</div>
        <div v-if="azone">
          <button class="adminform__btn btn btn_blue" @click.stop="del">Удалить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {  mapState } from 'vuex';

  export default {
    data: ()=>({
      azcreate: '',
      azone: '', 
      msg: '',
      msg2: '',
      timer: undefined,
      timer2: undefined
    }),
    methods: {
      async create(){
        this.$store.commit('setLocked', {component:'azcreate', value : true})
        await fetch(this.url + 'azone', {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({azone: this.azcreate.trim()})
        }).then(async res=>{
          if (!res.ok) throw(await res.text())
          this.msg = `Зона доступа ${this.azcreate.trim()} успешно создана`
          await this.$store.dispatch('adminSync')
        }).catch(err=>{console.error(err); alert(err)})
        this.$store.commit('setLocked', {component:'azcreate', value : false})
      },
      async del(){
        this.$store.commit('setLocked', {component:'azdelete', value : true})
        await fetch(this.url + 'azone', {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'DELETE',
          body: JSON.stringify({azone: this.azone.trim()})
        }).then(async res=>{
          if (!res.ok) throw(await res.text())
          this.msg2 = `Зона доступа ${this.azone.trim()} успешно удалена`
          await this.$store.dispatch('adminSync')
        }).catch(err=>{console.error(err); alert(err)})
        this.$store.commit('setLocked', {component:'azdelete', value : false})
      }
    },
    computed: {
      ...mapState(['azones', 'url'])
    },
    watch: {
      msg(){
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>this.msg = '', 10000)
      },
      msg2(){
        clearTimeout(this.timer2)
        this.timer2 = setTimeout(()=>this.msg2 = '', 10000)
      },
      azones(){
        this.azone = ''
      }
    }
  }
</script>

<style lang="sass" scoped>
</style>