<template>
  <button class="addsite" @click="activate" title="Добавить папку">+</button>
  <Transition name="fade" >
    <div class="addpopup" v-if="active" @click="active = false">
      <div class="addpopup__content" @click.stop>
        <div style="margin-bottom: 5px">Имя папки:</div>
        <input class="addpopup__input" placeholder="Имя" type="text" autocomplete="off" v-model="name">
        <div style="margin-bottom: 5px">Фильтр по сайтам:</div>
        <input class="addpopup__input" placeholder="Фильтр" type="text" autocomplete="off" v-model="filter">
        <div class="addpopup__checks">
          <label v-for="site in projects.filter(el=>el.toLowerCase().includes(filter.toLowerCase()))" :key="site">
            <input type="checkbox" v-model="checkmap[site]">
            <div>{{site}}</div>
          </label>
        </div>
        <div style="margin-bottom: 5px">Доступы:</div>
        <div class="addpopup__checks">
          <label v-for="azone in azones" :key="azone">
            <input type="checkbox" v-model="usermap[azone]">
            <div>{{azone}}</div>
          </label>
        </div>
        <button class="btn btn_blue" @click="add">Создать</button>
      </div>
    </div>
  </Transition>
</template>

<script>
import {  mapState } from 'vuex'
  export default {
    data: ()=>({
      active: false,
      name: '',
      filter: '',
      checkmap: {},
      usermap: {}
    }),
    methods: {
      async activate(){
        this.active = true
      },
      async add(){
        let sites = []
        let access = []
        for (let site in this.checkmap)
          if (this.checkmap[site])
            sites.push(site)
        for (let role in this.usermap)
          if (this.usermap[role])
            access.push(role)
        this.$store.commit('setLocked', {component: 'addproject', value: true})
        await fetch(this.url + 'editFolder', {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({folder: {fname: this.name, id: this.name, sites, access}, create: true })
        }).then(async res=>{console.log(res); 
          if (!res.ok) throw(await res.text())
          this.active = false
          await this.$store.dispatch('getProjects')
        }).catch(err=>alert(err))
        this.$store.commit('setLocked', {component: 'addproject', value: false})
      }
    },
    watch: {
      azones(val) {
        let obj = {}
        for (let azone of val)
          obj[azone] = false
        this.usermap = obj
      },
      projects(val) {
        this.checkmap = {}
        for (let site of val)
          this.checkmap[site] = false
      },
    },
    computed: {
      ...mapState(['azones','url','projects','folders'])
    }
  }
</script>

<style lang="sass">
.addsite
  border: 1px solid colors.$blue
  padding: 0
  outline: none
  width: 30px
  height: 30px
  display: flex
  cursor: pointer
  border-radius: 5px
  font-size: 24px
  align-items: center
  justify-content: center
  text-align: center
  background: lighten(colors.$blue, 30%)
  color: darken(colors.$blue, 30%)
  text-shadow: -1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)
.addpopup
  position: fixed
  z-index: 10
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  top: 0
  left: 0
  background: rgba(255,255,255,.6)
  &__content
    width: 400px
    max-width: 100%
    background: rgb(230, 230, 255)
    padding: 15px 20px
    border-radius: 20px
    box-shadow: 1px 1px 4px rgba(0,0,0,.3)
    >*:not(:last-child)
      margin-bottom: 15px
  &__input
    width: 100%
    padding: 5px 10px
    background: white
    border: 1px solid rgba(0,0,0,.15)
  &__checks
    max-height: 400px
    overflow-y: auto 
    label
      display: flex
      align-items: center
      font-size: 16px
      &:not:last-child
        margin-bottom: 8px
      input
        margin-right: 5px
</style>