<template>
  <div class="tablecontrolflex">
    <button class="tablesave btn btn_blue" @click="save">Сохранить таблицу</button>
    <button v-if="vaf" class="tablesave btn btn_blue" @click="$emit('changeVaf')">На таблицу очень точной частотности</button>
    <button v-if="interval[0] !== undefined" class="tablesave btn btn_blue" @click="exportData">Экспорт данных за интервал {{dates[interval[0]]}} – {{dates[(interval[1] !== undefined)?interval[1]:interval[0]]}}</button>
    <button class="tablesave btn btn_blue" @click="exportDataVig">Скачать выгрузки 
      <span v-if="interval[0] !== undefined">за интервал {{dates[interval[0]]}} – {{dates[(interval[1] !== undefined)?interval[1]:interval[0]]}}</span></button>
  </div>
  <table :style="{'--modecolor': modeColor}" :data-cols-width="getColWidth()" v-if="info && dates" class="table" ref="table" :class="{'sorted':sort.by && !sort.reverse, 'reversed':sort.by && sort.reverse}">
    <tr class="headline">
      <td data-a-h="center" data-a-v="middle" :class="{'active':sort.by == 'name'}" @click="changeSort('name')" ></td>
      <td data-a-h="center" data-a-v="middle" v-for="(date, i) in dates" :key="date" :class="{'active':sort.by == 'date' && sort.dateindex == i, 'border': interval.includes(i), 'inborder': i >= interval[0] && i <= interval[1]}" @click.exact="changeSort('date', i)" @click.alt.exact="setInt(i)">
        {{date.replace(/-/g,'.')}}
        <svg @click.stop="()=>deleteDate(date)" width="16px" height="16px" viewBox="0 0 16 16" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.29289 5.70711L6.58579 8L4.29289 10.2929L5.70711 11.7071L8 9.41421L10.2929 11.7071L11.7071 10.2929L9.41421 8L11.7071 5.70711L10.2929 4.29289L8 6.58579L5.70711 4.29289L4.29289 5.70711Z" fill="#FF5555" fill-rule="evenodd"/></svg>
      </td>
    </tr>
    <tr v-for="page of (limit? sortedpages.slice(0, limit): sortedpages)" :key="page">
      <td data-a-v="middle" class="link"><div>{{page}}</div></td>
      <td data-a-h="center" data-a-v="middle" v-for="(count,i) in pages[page]" :key="page + ' - ' + i">{{count}}</td>
    </tr>
  </table>
  <div class="tablestatus">Показан{{limit? `о ${limit} страниц`:'ы все страницы'}}</div>
  <div class="tablecontrolflex">
    <button class="tablesave btn btn_blue btn_sm" @click="limit += 50" v-if="!limit || (limit && (limit < sortedpages.length))">{{limit?'+':'Показать '}}50</button>
    <button class="tablesave btn btn_blue btn_sm" @click="limit += 100" v-if="!limit || (limit && (limit + 50 < sortedpages.length))">{{limit?'+':'Показать '}}100</button>
    <button class="tablesave btn btn_blue btn_sm" v-if="limit" @click="limit = false">Показать все ({{sortedpages.length}})</button>
  </div>
</template>

<script>
import TableToExcel from "@linways/table-to-excel"
import { mapState } from "vuex"
  export default {
    data: ()=>({
      interval: [undefined, undefined],
      sort: {
        by: 'name',
        dateindex: undefined,
        reverse: false
      },
      limit: 50
    }),
    props: {
      vaf: {type: Boolean, default: false},
      info: {},
      dates: {},
      modeColor: {type: String},
      mode: {type: String}
    }, 
    computed:{
      ...mapState(['url','id']),
      pages(){
        let data = {}
        let count = 0
        for (let date of this.dates) {
          let p = new Set()
          let list = this.info[date]
          let curdata = {}
          for (let line of list) {
            p.add(line.page)
            if (!curdata[line.page])
              curdata[line.page] = 0
            curdata[line.page]++
          }
          p = [...p]
          for (let page of p)
            if (!data[page])
              data[page] = new Array(count).fill(0)
          for (let page in data)
            data[page].push(curdata[page] || 0)
          count++
        }
        return data
      },
      sortedpages(){
        let pages = Object.keys(this.pages)
        if (!this.sort.by)
          return pages
        if (this.sort.by == 'name')
          pages.sort((a,b)=>(a.localeCompare(b)) * (this.sort.reverse?(-1):1))
        if (this.sort.by == 'date')
          pages.sort((a,b)=>
            (this.pages[a][this.sort.dateindex] - this.pages[b][this.sort.dateindex]) 
            * (this.sort.reverse?(-1):1))
        return pages
      }
    },
    watch: {
      limit(v){
        if (v !== false && v > this.sortedpages.length) 
          this.limit = false
      },
      info(){
        if (this.sort.by != 'name')
          this.sort = {
            by: 'name',
            dateindex: undefined,
            reverse: false
          }
        this.interval = [undefined, undefined]
      }
    },
    methods: {
      async deleteDate(date){
        console.log(date, this.mode, this.id)
        this.$store.commit('setLocked', {component: 'tableDeleting', value: true})
        await fetch(this.url + `data/${this.id}/${date}`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'DELETE'
        }).then(async res=>{
          if (!res.ok) throw (await res.text())
          this.$emit('dataUpdated')
        }).catch(err=>alert(err))
        this.$store.commit('setLocked', {component: 'tableDeleting', value: false})
      },
      exportData(){
        let tabledata = JSON.parse(JSON.stringify(this.pages))
        for (let page in tabledata)
          tabledata[page] = tabledata[page].filter((el,i) => (i >= this.interval[0]) && (i <= this.interval[1]))
        let data = {
          tabledata,
          sortedpages: this.sortedpages,
          interval: this.interval
        }
        this.$emit('export', data)
      },
      exportDataVig(){
        this.$emit('exportVig', this.interval)
      },
      setInt(i){
        let idx = this.interval.indexOf(i)
        if (idx != -1) {
          this.interval[idx] = undefined
          return this.interval.sort()
        }
        idx = this.interval.indexOf(undefined)
        if (idx != -1) {
          this.interval[idx] = i
          return this.interval.sort()
        }
        let a = this.interval[0], b = this.interval[1]
        if (Math.abs(a-i)>Math.abs(b-i))
          this.interval[1] = i
        else
          this.interval[0] = i
        this.interval.sort()
      },
      getColWidth(){
        let str = '40' + this.dates.map(()=>',10').join('')
        return str
      },
      getColor(page, index, table) {
        if (table)
          return 'FFFFFFFF'
        return 'white'
        /*
        switch(v) {
          case true: 
            return 'FF64C864'
          case false:
            return '0ADC3545'
          case null:
            return 'FF222222'
          case undefined:
            return 'FF999999'
        }*/
      },
      save(){
        TableToExcel.convert(this.$refs.table, {
          name: "table.xlsx",
          sheet: {
            name: "Данные"
          }
        })
      },
      changeSort(by, index) {
        if (index !== undefined)
          this.sort.dateindex = index
        if (this.sort.by == by)
          this.sort.reverse = !this.sort.reverse
        else {
          this.sort.reverse = false
          this.sort.by = by
        }
      }
    }
  }
</script>

<style lang="sass">
$danger: #dc3545
$yandex: #ffc107
$google: #4680FF
$success: #198754
$primary: #0d6efd
.headline
  position: sticky
  top: 0
  z-index: 2
  td
    background: white
    svg
      display: block
      position: absolute
      width: 14px
      height: 14px
      top: 0
      right: 0
      transform-origin: center center
      transition: transform .3s ease-out
      transform: translate(50%, -50%) scale(0)
      cursor: pointer
      pointer-events: none
      border-radius: 50%
      background: white
      opacity: 0.5
    &:hover 
      z-index: 5
      svg
        z-index: 1
        transform: translate(50%, -50%) scale(1)
        pointer-events: auto
        &:hover
          opacity: 1
.tablestatus
  padding: 20px 0 0
.tablecontrolflex
  display: flex
  gap: 20px
.tablesave
  max-width: fit-content
  margin-top: 10px
  margin-bottom: 30px
.modeswitcher
  margin-bottom: 10px
  &__title
    font-size: 18px
    margin-bottom: 5px
  &, label
    display: flex
    align-items: center
  gap: 10px
  input
    display: block
    margin-right: 5px
.table  
  display: block
  max-width: 100%
  font-size: 14px
  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  tr
    font-weight: 500
    white-space: nowrap
  td
    padding: 3px 10px
    position: relative
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:not(:first-child)
      text-align: center
    &.link
      z-index: 2
      max-width: 400px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      &:hover 
        overflow: visible
        padding: 0
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: white
    &.found
      background: rgba(100, 200, 100, .4)
      &:hover
        background: rgba($success, .2)
    &.yandex
      background: rgba($yandex, .3)
      &:hover
        background: rgba($yandex, .15)
    &.google
      background: rgba($google, .3)
      &:hover
        background: rgba($google, .15)
    &.mention
      background: rgba($yandex, .18)
      &:hover
        background: rgba($yandex, .1)
    &.notfound
      background: rgba($danger, .2)
      &:hover
        background: rgba($danger, .1)
    &.broken
      background: rgba(black, .8)
      &:hover
        background: rgba(black, .6)
    &.unknown
      background: rgba(black, .2)
      &:hover
        background: rgba(black, .1)
  .headline td.inborder
    border-color: var(--modecolor)
  .headline td.border
    border: 3px solid var(--modecolor)
    margin: -1px
  &.sorted .headline td.active
    position: relative
    background-image: linear-gradient(rgba(255,255,255,.8) 0%, rgba(255,255,255,.8) 100%), linear-gradient(var(--modecolor) 0%, var(--modecolor) 100%)
    &::before, &::after
      display: block
      position: absolute
      left: 0
      top: 3px
      font-weight: 900
      content: '↓'
    &::after
      left: initial
      right: 0
  &.reversed .headline td.active
    position: relative
    background-image: linear-gradient(rgba(255,255,255,.8) 0%, rgba(255,255,255,.8) 100%), linear-gradient(var(--modecolor) 0%, var(--modecolor) 100%)
    &::before, &::after 
      display: block
      position: absolute
      left: 0
      top: 3px
      font-weight: 900
      content: '↑'
    &::after
      left: initial
      right: 0

</style>