<template>
  <button class="addsite" @click="activate" title="Открыть админку">A</button>
  <AdminPanel v-model="active" />
</template>
<script>
import AdminPanel from './AdminPanel.vue';
  export default {
    data: () => ({
      active: false
    }),
    components: { AdminPanel },
    methods: {
      async activate() {
        await this.$store.dispatch('adminSync')
        this.active = true
      }
    },
    watch: {
      active(v,old) {
        if (!v && old)
          this.$emit('closed')
      }
    }
}
</script>

<style lang="sass" scoped>

</style>