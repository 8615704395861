import { createStore } from 'vuex'

export default createStore({
  state: {
    id: undefined,
    folder: undefined,
    folders: [],
    azones: [],
    users: [],
    locked: { 'main': false, 'login': false },
    url: '',
    projects: [],
    authorized: false,
    pending: false
  },
  mutations: {
    setAuthorized(state, value) {
      state.authorized = value
    },
    setPending(state, value) {
      state.pending = value
    },
    setLocked(state, { component, value }) {
      state.locked[component] = value
    },
    setFolders(state, folders) {
      state.folders = folders
    },
    setAzones(state, azones) {
      state.azones = azones
    },
    setUsers(state, users) {
      state.users = users
    },
    setFolder(state, folder) {
      state.folder = folder
    },
    setId(state, id) {
      state.id = id
    },
    setProjects(state, list) {
      state.projects = list
    },
    setUrl(state, url) {
      state.url = url
    }
  },
  actions: {
    async adminSync({ state, commit }) {
      commit('setLocked', { component: 'adminSync', value: true })
      await fetch(state.url + 'admin', {credentials: 'include'}).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.json()
      }).then(ans => {
        commit('setUsers', ans.users)
        commit('setAzones', ans.azones)
      })
        .catch((err) => { console.error(err); alert(`Ошибка adminSync:\n${err}`) })
      commit('setLocked', { component: 'adminSync', value: false })
    },
    async waitJob({ state, commit }, { job, cb, start }) {
      commit('setLocked', { component: job, value: 'Ожидание получения статуса задачи...' })
      let done = false, errors = 0
      console.log(job)
      while (!done) {
        await fetch(state.url + `job/${job}`, {credentials: 'include'}).then(async res => {
          if (!res.ok) throw res.status
          return res.json()
        })
          .then(async json => {
            console.log(json)
            if (json.status === 'done') {
              done = true
              const time = Math.round((new Date(json.doneAt) - (start || new Date(json.createdAt))) / 10) / 100
              const oldTime = Math.round((new Date(json.doneAt) - (new Date(json.createdAt))) / 10) / 100
              console.log(oldTime)
              console.log(time)
              const str = `Задача выполнена за ${time} сек.`
              console.log(str)
              commit('setLocked', { component: job, value: str })
              await new Promise(r => setTimeout(r, 2000));
              if (cb)
                cb()
            } else if (json.error) {
              done = true
              alert(json.error)
              commit('setLocked', { component: job, value: `${json.error}` })
            } else {
              commit('setLocked', { component: job, value: json.status })
            }
            if (!done)
              await new Promise(r => setTimeout(r, 2000));
          })
          .catch(err => {
            console.error(err)
            errors++
            if ((errors == 3) && !done) {
              alert(err)
              done = true
            }
          })
      }
      commit('setLocked', { component: job, value: false })
    },
    async getProjects({ state, commit }) {
      commit('setLocked', { component: 'getProjects', value: true })
      await fetch(state.url + 'list/', { credentials: 'include' }).then(async res => {
        if (!res.ok) throw (await res.text())
        return res.json()
      }).then(ans => {
        commit('setFolders', ans.folders)
        commit('setProjects', ans.projects)
        commit('setAzones', ans.azones)
      })
        .catch((err) => { console.error(err); alert(`Ошибка getProjects:\n${err}`) })
      commit('setLocked', { component: 'getProjects', value: false })
    },
    async checkLoging({ state, commit }) {
      commit('setPending', true)
      await fetch(state.url + 'auth', {credentials: 'include'})
        .then(async res => {
          if (!res.ok)
            throw (await res.text())
          return res.json()
        })
        .then(role => commit('setAuthorized', role))
        .catch(err => alert(err))
      commit('setPending', false)
    }
  },
  getters: {
    usersLogins(state) {
      return state.users.map(el => el._id)
    },
    curFoldProjects(state, getters) {
      if (!getters.curFold) return []
      return getters.curFold.projects
    },
    curFold(state) {
      if (!state.folders) return undefined
      return state.folders[state.folder]
    },
    foldersList(state) {
      if (!state.folders) return []
      return Object.keys(state.folders)
    },
    folderList(state, getters) {
      if (!getters.curFold) return []
      return getters.curFold.projects
    },
    locked(state) {
      let res = Object.values(state.locked).reduce((a, b) => a || b)
      return res
    }
  },
  modules: {
  }
})
