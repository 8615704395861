<template>
  <div class="tablecontrolflex">
    <button class="tablesave btn btn_blue" @click="save">Сохранить таблицу</button>
    <button class="tablesave btn btn_blue" @click="$emit('changeVaf')">На основную таблицу</button>
  </div>
  <table :style="{'--modecolor': modeColor}" :data-cols-width="getColWidth()" v-if="info && dates" class="table" ref="table" :class="{'sorted':sort.by && !sort.reverse, 'reversed':sort.by && sort.reverse}">
    <tr class="headline">
      <td data-a-h="center" data-a-v="middle" :class="{'active':sort.by == 'name'}" @click="changeSort('name')" ></td>
      <td data-a-h="center" data-a-v="middle" v-for="(date, i) in dates" :key="date" :class="{'active':sort.by == 'date' && sort.dateindex == i}" @click.exact="changeSort('date', i)">{{date}}</td>
    </tr>
    <tr v-for="page of (limit? sortedpages.slice(0, limit): sortedpages)" :key="page">
      <td data-a-v="middle" class="link"><div>{{page}}</div></td>
      <td data-a-h="center" data-a-v="middle" v-for="(count,i) in pages[page]" :key="page + ' - ' + i">{{count}}</td>
    </tr>
  </table>
  <div class="tablestatus">Показан{{limit? `о ${limit} страниц`:'ы все страницы'}}</div>
  <div class="tablecontrolflex">
    <button class="tablesave btn btn_blue btn_sm" @click="limit += 50" v-if="!limit || (limit && (limit < sortedpages.length))">{{limit?'+':'Показать '}}50</button>
    <button class="tablesave btn btn_blue btn_sm" @click="limit += 100" v-if="!limit || (limit && (limit + 50 < sortedpages.length))">{{limit?'+':'Показать '}}100</button>
    <button class="tablesave btn btn_blue btn_sm" v-if="limit" @click="limit = false">Показать все ({{sortedpages.length}})</button>
  </div>
</template>

<script>
import TableToExcel from "@linways/table-to-excel"
  export default {
    data: ()=>({
      sort: {
        by: 'name',
        dateindex: undefined,
        reverse: false
      },
      limit: 50
    }),
    props: {
      info: {},
      rawdates: [],
      modeColor: {type: String}
    }, 
    computed:{
      dates(){
        let res = []
        for (let date of this.rawdates)
          if (this.info[date][0] && (this.info[date][0].vaf !== undefined))
            res . push(date)
        return res
      },
      pages(){
        let data = {}
        let count = 0
        for (let date of this.dates) {
          let p = new Set()
          let list = this.info[date]
          let curdata = {}
          for (let line of list) {
            p.add(line.page)
            if (!curdata[line.page])
              curdata[line.page] = 0
            if (line.pos <= 20 )
              curdata[line.page] += (parseInt(line.vaf) || 0)
          }
          p = [...p]
          for (let page of p)
            if (!data[page])
              data[page] = new Array(count).fill(0)
          for (let page in data)
            data[page].push((Math.round(curdata[page] * 15 / 100)) || 0)
          count++
        }
        return data
      },
      sortedpages(){
        let pages = Object.keys(this.pages)
        if (!this.sort.by)
          return pages
        if (this.sort.by == 'name')
          pages.sort((a,b)=>(a.localeCompare(b)) * (this.sort.reverse?(-1):1))
        if (this.sort.by == 'date')
          pages.sort((a,b)=>
            (this.pages[a][this.sort.dateindex] - this.pages[b][this.sort.dateindex]) 
            * (this.sort.reverse?(-1):1))
        return pages
      }
    },
    watch: {
      limit(v){
        if (v !== false && v > this.sortedpages.length) 
          this.limit = false
      },
      info(){
        if (this.sort.by != 'name')
          this.sort = {
            by: 'name',
            dateindex: undefined,
            reverse: false
          }
      }
    },
    methods: {
      getColWidth(){
        let str = '40' + this.dates.map(()=>',10').join('')
        return str
      },
      getColor(page, index, table) {
        if (table)
          return 'FFFFFFFF'
        return 'white'
      },
      save(){
        TableToExcel.convert(this.$refs.table, {
          name: "table.xlsx",
          sheet: {
            name: "Данные"
          }
        })
      },
      changeSort(by, index) {
        if (index !== undefined)
          this.sort.dateindex = index
        if (this.sort.by == by)
          this.sort.reverse = !this.sort.reverse
        else {
          this.sort.reverse = false
          this.sort.by = by
        }
      }
    }
  }
</script>

<style lang="sass">

</style>