import { createRouter, createWebHistory } from 'vue-router'
import Main from '../views/Main.vue'

const routes = [
  {
    path: '/',
    redirect: '/app'
  },
  {
    path: '/app/:folder/:id',
    alias: ['/app', '/app/:folder'],
    name: 'Main',
    component: Main
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
