<template>
  <div class="adminform">
    <div class="adminform__label-text">Пользователь</div>
    <select v-model="login">
      <option value=""></option>
      <option v-for="l in usersLogins" :key="l" :value="l">{{l}}</option>
    </select>
    <div class="adminform__msg" v-if="msg">{{msg}}</div>
    <div v-if="login">
      <label class="adminform__label">
        <div class="adminform__label-text">Логин</div>
        <input type="text" autocomplete="off" v-model="user.log">
      </label>
      <label class="adminform__label">
        <div class="adminform__label-text">Пароль</div>
        <input type="text" autocomplete="off" v-model="user.pass">
      </label>
      <div class="adminform__label-text">Зоны доступа</div>
      <div class="adminform__zones">
        <label class="adminform__zonelabel" v-for="azone in azones" :key="azone">
          <input type="checkbox" v-model="azonemap[azone]">
          <span class="adminform__zonelabel-text">{{azone}}</span>
        </label>
      </div>
      <div class="adminform__label-text">Пользователю будут видны папки: {{this.visibleFolders}}</div>
      <button class="adminform__btn btn btn_blue" @click.stop="edit">Изменить</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

  export default {
    name: 'createUser',
    data: ()=>({
      login: '', 
      msg: '',
      timer: undefined,
      user: {
        log: '',
        pass: ''
      },
      azonemap: {}
    }),
    methods: {
      async edit(){
        this.$store.commit('setLocked', {component:'editUser', value : true})
        const log = this.login.trim(), newlog = this.user.log.trim()
        let obj = {log: log, pass: this.user.pass.trim(), azones: this.curAzones}
        if (log != newlog)
          obj.newlog == newlog
        await fetch(this.url + 'user', {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'PUT',
          body: JSON.stringify(obj)
        }).then(async res=>{
          if (!res.ok) throw(await res.text())
          this.msg = `Пользователь ${log} успешно изменён`
          await this.$store.dispatch('adminSync')
        }).catch(err=>{console.error(err); alert(err)})
        this.$store.commit('setLocked', {component:'editUser', value : false})
      }
    },
    computed: {
      curAzones(){
        let azones = []
        for (let key in this.azonemap)
          if (this.azonemap[key])
            azones.push(key)
        return azones
      },
      ...mapGetters(['usersLogins']),
      ...mapState(['users', 'url', 'azones','folders']),
      curUser(){
        if (!this.login) return {azones:[]}
        return this.users.find(u=>u._id == this.login)
      },
      visibleFolders(){
        if (!this.login) return []
        let res = []
        for (let f in this.folders)
          if (this.folders[f].access . filter(el=>this.curAzones.includes(el)).length)
            res.push(f)
        return res.join(', ')
      }
    },
    watch: {
      azones(val) {
        let obj = {}
        for (let az of val) {
          obj[az] = this.curUser.azones.includes(az) || false
        }
        this.azonemap = obj
      },
      msg(){
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>this.msg = '', 10000)
      },
      usersLogins(){
        this.login = ''
      },
      login(v){
        this.user.log = v
        this.user.pass = ''
        let obj = {}
        for (let az of this.azones) {          
          obj[az] = this.curUser.azones.includes(az) || false
        }
        this.azonemap = obj
      }
    }
  }
</script>

<style lang="sass" scoped>
</style>