<template>
  <div class="adminform">
    <div class="adminform__label-text">Пользователь</div>
    <select v-model="login">
      <option value=""></option>
      <option v-for="l in usersLogins" :key="l" :value="l">{{l}}</option>
    </select>
    <div class="adminform__msg" v-if="msg">{{msg}}</div>
    <div v-if="login">
      <button class="adminform__btn btn btn_blue" @click.stop="del">Удалить</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

  export default {
    data: ()=>({
      login: '', 
      msg: '',
      timer: undefined
    }),
    methods: {
      async del(){
        this.$store.commit('setLocked', {component:'deleteUser', value : true})
        const log = this.login
        await fetch(this.url + 'user', {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'DELETE',
          body: JSON.stringify({log: log})
        }).then(async res=>{
          if (!res.ok) throw(await res.text())
          this.msg = `Пользователь ${log} успешно удалён`
          await this.$store.dispatch('adminSync')
        }).catch(err=>{console.error(err); alert(err)})
        this.$store.commit('setLocked', {component:'deleteUser', value : false})
      }
    },
    computed: {
      ...mapGetters(['usersLogins']),
      ...mapState(['users', 'url'])
    },
    watch: {
      msg(){
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>this.msg = '', 10000)
      },
      usersLogins(){
        this.login = ''
      }
    }
  }
</script>

<style lang="sass" scoped>
</style>