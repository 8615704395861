<template>
  <div class="adminform">
    <div class="adminform__msg" v-if="msg">{{msg}}</div>
    <label class="adminform__label">
      <div class="adminform__label-text">Логин</div>
      <input type="text" autocomplete="off" v-model="user.log">
    </label>
    <label class="adminform__label">
      <div class="adminform__label-text">Пароль</div>
      <input type="text" autocomplete="off" v-model="user.pass">
    </label>
    <div class="adminform__label-text">Зоны доступа</div>
    <div class="adminform__zones">
      <label class="adminform__zonelabel" v-for="azone in azones" :key="azone">
        <input type="checkbox" v-model="azonemap[azone]">
        <span class="adminform__zonelabel-text">{{azone}}</span>
      </label>
    </div>
    <button class="adminform__btn btn btn_blue" @click.stop="create">Создать</button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    name: 'createUser',
    data: ()=>({
      msg: '',
      timer: undefined,
      user: {
        log: '',
        pass: ''
      },
      azonemap: {}
    }),
    methods: {
      async create(){
        this.$store.commit('setLocked', {component:'createUser', value : true})
        let azones = []
        for (let key in this.azonemap)
          if (this.azonemap[key])
            azones.push(key)
        const log = this.user.log.trim()
        await fetch(this.url + 'user', {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({log: log, pass: this.user.pass.trim(), azones})
        }).then(async res=>{
          if (!res.ok) throw(await res.text())
          this.msg = `Пользователь ${log} успешно создан`
          await this.$store.dispatch('adminSync')
        }).catch(err=>{console.error(err); alert(err)})
        this.$store.commit('setLocked', {component:'createUser', value : false})
      }
    },
    computed: {
      ...mapState(['url', 'azones']),
    },
    watch: {
      azones(val) {
        let obj = {}
        for (let az of val)
          obj[az] = false
        this.azonemap = obj
      },
      msg(){
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>this.msg = '', 10000)
      }
    }
  }
</script>

<style lang="sass">
.adminform
  &__msg
    color: #88FF88
    margin-bottom: 15px
    font-weight: 700
    font-size: 18px
  &__btn
    margin-top: 35px
  &__label
    display: block
    margin-bottom: 15px
    &-text
      margin-bottom: 3px
    input:not([type=checkbox]):not([type=radio]), select
      max-width: 250px
      width: 100%
      padding: 5px 10px
      background: white
      border: 1px solid rgba(0,0,0,.15)  
  select
    display: block
    margin-bottom: 15px
    max-width: 250px
    width: 100%
    padding: 5px 10px
    background: white
    border: 1px solid rgba(0,0,0,.15)  
  &__zones
    width: fit-content
    margin-bottom: 15px
    max-width: 100%
    min-width: 250px
    border-radius: 5px
    padding: 10px 15px
    background: colors.$blue
  &__zonelabel
    display: flex
    align-items: center
    &-text
      margin-left: 5px
    &:not(:last-child)
      margin-bottom: 5px
</style>