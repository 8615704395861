<template>
  <div class="login">
    <div class="login__content">
      <input ref="login" name="login" type="text" class="login__field" placeholder="Логин">
      <input ref="password" name="password" type="password" class="login__field" placeholder="Пароль">
      <div class="login__line">
        <div class="login__failed" v-if="failed">Неверный логин и/или пароль</div>        
        <button class="btn btn_blue" @click="login">Войти</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

  export default {
    data: ()=>({
      failed: false,
    }),
    mounted(){
      this.failed = false
    },
    methods: {
      async login(){
        this.$store.commit('setLocked',{component: 'login', value: true})

        await fetch(this.url + 'auth', { 
            credentials: 'include',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              login: this.$refs.login.value,
              password: this.$refs.password.value
            })
          })
          .then(async res=>{
            
            if(!res.ok) throw(await res.text())
            return res.json()
          })
          .then(async ok=>{
            if (!ok)
              return this.failed = true
            await new Promise(resolve => setTimeout(resolve, 500))
            this.$store.commit('setAuthorized', ok)
          })
          .catch(err=>alert(err))
        this.$store.commit('setLocked',{component: 'login', value: false})
      }
    },
    computed: {
      ...mapState(['url'])
    }
  }
</script>

<style lang="sass" scoped>
@keyframes fall
  0%
    transform: translateY(-50%)
    opacity: 0
  100%
    transform: none
    opacity: 1
.login
  position: fixed
  width: 100%
  height: 100%
  z-index: 100
  background: #777
  &__failed
    font-size: 12px
    font-weight: 700
    color: rgb(255,100,100)
    text-shadow: 1px 1px 2px rgb(255,180,180)
  &__line
    width: 100%
    display: flex
    align-items: center
  &, &__content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  &__content
    animation: fall .6s ease-out
    padding: 15px
    max-width: 100%
    width: 300px
    border-radius: 10px
    background: linear-gradient(142deg, darken(colors.$blue, 68%) 0%, darken(colors.$blue, 58%) 100%)
    >*:not(:last-child)
      margin-bottom: 20px
    .btn.btn_blue
      padding-top: 3px
      padding-bottom: 3px
      margin-left: auto
  &__field
    width: 100%
    border: none
    outline: none
    border-radius: 5px
    background: white
    padding: 10px 20px
    font-size: 16px
    &::placeholder
      color: rgba(0,0,0,.4) 
</style>