<template>
  <router-view/>
</template>

<style lang="sass">
body
  margin: 0
  padding: 0
  min-height: 100vh
  display: flex
  flex-direction: column
  *, &::before, &::after
    box-sizing: border-box  
    margin: 0
    padding: 0
    outline: none
#app
  position: relative
  width: 100%
  flex-grow: 1
  font-family: 'Muller', 'Open Sans', Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #2c3e50
  
.btn
  display: inline-block
  font-weight: 400
  text-align: center
  white-space: nowrap
  vertical-align: middle
  user-select: none
  border: 1px solid transparent
  padding: 6px 12px
  font-size: 16px
  line-height: 1.5
  border-radius: 4px
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
  cursor: pointer
  &_sm
    font-size: 12px
  &_blue
    color: white
    background: colors.$bootblue
    &:hover
      background: colors.$boothover
    &:active
      background: colors.$bootactive
  &_yellow
    color: black
    background: #ffc107
    &:hover
      background: #ffca2c
    &:active
      background: #ffcd39
</style>
