<template>
  <div>
    <select class="siteselect" @input="setProject" ref="select">
      <option></option>
      <option :key="project" v-for="project in curFoldProjects" :value="project">{{project}}</option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

  export default {
    methods: {
      setProject() {
        const select = this.$refs.select
        const id = select.value
        if (id == this.id) return
        if (!id) {
          this.$router.push(`/app${this.folder?`/${this.folder}`:''}`)
          return console.error('Попытка выбрать пустой проект')
        }
        if (!(this.curFoldProjects.includes(id))) return alert('Ошибка. Указанный проект не существует.')
        this.$router.push(`/app/${this.folder}/${id}`)
      }      
    },
    mounted(){
      this.$store.dispatch('getProjects')
      if (this.id) 
        return this.$refs.select.value = this.id
      let val = this.$refs.select.value
      if (val && this.folder) 
        this.$router.push(`/app/${this.folder}/${val}`)
    },
    updated(){
      if (this.id) 
        return this.$refs.select.value = this.id

      let val = this.$refs.select.value
      if (val && this.folder) 
        this.$router.push(`/app/${this.folder}/${val}`)
    },
    watch: {
      id(v) {
        if (v && v.trim() != this.$refs.select.value)  {
          this.$refs.select.value = v.trim()
          this.$refs.select.value = v.trim()
        }
      }
    }, 
    computed: {
      ...mapState(['id', 'url','folder']),
      ...mapGetters(['curFoldProjects'])
    } 
  }
</script>

<style lang="sass" scoped>
.siteselect
  width: 150px
  height: 30px
</style>