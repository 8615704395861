<template>
  <div>
    <select class="siteselect" @input="setFolder" ref="select">
      <option></option>
      <option :key="folder" v-for="folder in foldersList" :value="folder">{{folder}}</option>
    </select>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

  export default {
    methods: {
      setFolder() {
        const select = this.$refs.select
        const folder = select.value
        if (folder == this.folder) return
        if (!folder) {
          this.$router.push(`/app`)
          return console.error('Попытка выбрать пустую папку')
        }
        if (!this.foldersList.includes(folder)) return alert('Ошибка. Указанный проект не существует.')
        if (this.id && !this.folders[folder].projects.includes(this.id))
          this.$store.commit('setId', undefined)
        
        this.$store.commit('setFolder', folder)
      }      
    },
    mounted(){
      this.$store.dispatch('getProjects')
      if (this.folder)  {
        this.$refs.select.value = this.folder
        console.log(this.$refs.select.value)
      }
    },
    watch: {
      folder(v) {
        console.log(v)
        if (v && v.trim() != this.$refs.select.value)  {
          this.$refs.select.value = v.trim()
        }
      },
      foldersList() {
        let v = this.folder
        if (v && v.trim() != this.$refs.select.value)  {
          this.$refs.select.value = v.trim()
        }
      }
    }, 
    computed: {
      ...mapState(['id','folder','projects', 'url','folders']),
      ...mapGetters(['foldersList'])
    } 
  }
</script>

<style lang="sass" scoped>
.siteselect
  width: 150px
  height: 30px
</style>