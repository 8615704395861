<template>
  <div class="adminform">
    <div class="adminform__msg" v-if="msg">{{ msg }}</div>
    <div style="display: flex; flex-wrap: wrap; gap: 40px">
      <div>
        <div class="adminform__label-text">Фильтр по папкам</div>
        <div class="adminform__zones">
          <label class="adminform__zonelabel" v-for="folder in foldersList" :key="folder">
            <input type="checkbox" :name="folder" v-model="selectedFolders[folder]">
            <span class="adminform__zonelabel-text">{{ folder }}</span>
          </label>
        </div>
      </div>
      <div>
        <div class="adminform__label-text">Проекты</div>
        <div class="adminform__zones" style="max-height: 400px; overflow-y: auto">
          <label class="adminform__zonelabel" v-for="project in projectsList" :key="project">
            <input type="checkbox" :name="project" @keydown.space.stop.prevent="selectedProjects[project] = !selectedProjects[project]" @keyup.space.stop.prevent @keypress.space.stop.prevent v-model="selectedProjects[project]">
            <span class="adminform__zonelabel-text">{{ project }}</span>
          </label>
        </div>
        <div class="adminform__smlbtnflex" v-if="projectsList.length">
          <button @click="selectAll" class="btn btn_blue">{{ AllChecked ? 'Снять все' : 'Выбрать все' }}</button>
        </div>
        <button class="adminform__btn btn btn_blue" @click.stop="del">Удалить</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'DeleteDomains',
  data: () => ({
    msg: '',
    selectedFolders: {},
    selectedProjects: {},
  }),
  computed: {
    ...mapState(['url', 'folders', 'folder']),
    ...mapGetters(['foldersList']),
    AllChecked() {
      if (!this.projectsList.length) return false
      for (let project of this.projectsList)
        if (!this.selectedProjects[project]) return false
      return true
    },
    projectsList() {
      let res = []
      for (let f in this.selectedFolders) {
        if (this.selectedFolders[f] == true)
          res.push(this.folders[f].projects)
      }
      res = [...new Set(res.flat())]
      res.sort((a, b) => a.localeCompare(b))
      return res
    }
  },
  methods: {
    selectAll() {
      let val = true
      if (this.AllChecked)
        val = false
      for (let project of this.projectsList)
        this.selectedProjects[project] = val
    },
    async deleteProj(proj) {
      await fetch(this.url + `data/${proj}`, {
        credentials: 'include',
        method: 'DELETE'
      }).then(res => {
        if (res.ok) return res.status
        throw ('Что-то пошло не так при удалении ' + proj + ' (' + (res.status) + ')')
      }).catch(err => alert(err))
    },
    async del() {
      this.$store.commit('setLocked', { component: 'deleteDomains', value: true })
      let suc = 0, errs = 0
      let selected = []
      for (let proj in this.selectedProjects)
        if (this.selectedProjects[proj])
          selected.push(proj)
      if (selected.length) {
        for (let proj of selected) {
          try {
            await this.deleteProj(proj)
            suc++
          } catch (err) {
            errs++
          }
        }
        alert(`Успешно: ${suc}\nОшибок: ${errs}`)
        if (this.folder) {
          this.$store.dispatch('getProjects')
          this.$router.push(`/app${this.folder ? `/${this.folder}` : ''}`)
        }
      }
      this.$store.commit('setLocked', { component: 'deleteDomains', value: false })
    }
  },
  watch: {
    foldersList(v) {
      let obj = {}
      if (v && v.length)
        for (let f of v)
          obj[f] = false
      this.selectedFolders = obj
    },
    projectsList(v) {
      let obj = {}
      if (v && v.length)
        for (let p of v)
          obj[p] = false
      this.selectedProjects = obj
    }
  }
}
</script>

<style lang="sass">
.adminform
  &__zonelabel
    input[type=checkbox]:focus-visible ~ *
      font-weight: 700
  &__smlbtnflex
    display: flex
    gap: 10px
    button
      padding: 4px 8px
      font-size: 11px
      font-weight: 700
</style>